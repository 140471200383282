<template>
  <div>
    <h2 class="font-bold">歷史展覽策展人展品説明紀錄</h2>
    <div class="flex mt-1">
      <select
        class="form-select"
        v-model="form.id"
        placeholder="請選擇展覽名稱"
      >
        <option :value="''" v-if="!rows.length">目前沒有資料</option>
        <option v-for="row in rows" :value="row.id" :key="row.id">
          {{ row.exhib }}
        </option>
      </select>

      <El-Button v-if="action" class="ml-2" @click="to">前往展覽</El-Button>
    </div>

    <div class="my-4" v-if="cur">
      <p v-if="cur.excerpt" v-html="cur.excerpt"></p>
      <p v-else>目前沒有資料</p>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, ref } from 'vue';
import WorkService from '@/services/Work';
import { ElButton } from 'element-plus';

export default {
  props: {
    work_id: {
      type: String,
    },
    chip_id: {
      type: String,
    },
    action: {
      type: Boolean,
      default: false,
    },
  },
  components: { ElButton },
  setup(props) {
    const { work_id, chip_id } = toRefs(props);
    const form = reactive({ id: '' });
    const rows = reactive([]);
    const loading = ref(false);
    loading.value = true;
    WorkService.history({
      id: work_id.value,
    })
      .then((res) => {
        if (res.code !== 1) {
          return;
        }

        if (chip_id.value) {
          const idx = res.data.subset.findIndex((x) => x.id === chip_id.value);
          if (idx > -1) {
            res.data.subset.splice(idx, 1);
          }
        }
        rows.push(...res.data.subset);
      })
      .finally(() => {
        loading.value = false;
      });

    const cur = computed(() => {
      return rows.find((x) => form.id === x.id);
    });
    const to = () => {
      window.location.href = `/exhib/${cur.value.exhib_id}/${cur.value.slug}`;
    };
    return { rows, cur, form, to };
  },
};
</script>

<style></style>
