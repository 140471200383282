<template>
  <div class="search-menu">
    <el-button class="p-3" @click.prevent="show = true" type="primary">
      <img class="w-6" src="@/assets/search.svg" alt="" />
    </el-button>

    <el-drawer v-model="show" :size="300" :show-close="false">
      <template v-slot:header>
        <div class="h-[52px] bg-primary flex">
          <el-button
            @click.prevent="show = false"
            type="primary"
            class="ml-auto mr-4"
          >
            <el-icon color="#fff" :size="30">
              <close />
            </el-icon>
          </el-button>
        </div>
      </template>
      <div class="flex flex-col text-center h-full p-4">
        <el-form
          :label-position="'top'"
          :model="form"
          v-on:keypress.enter="submit"
        >
          <el-form-item label="關鍵字">
            <el-input v-model="form.keyword"></el-input>
          </el-form-item>
          <el-button
            :disabled="!form.keyword"
            class="lg"
            @click.prevent="submit"
            type="primary"
          >
            搜尋
          </el-button>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  ElDrawer,
  ElButton,
  ElInput,
  ElForm,
  ElFormItem,
  ElIcon,
} from 'element-plus';

import { Close } from '@element-plus/icons';
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  components: {
    ElIcon,
    Close,
    ElDrawer,
    ElButton,
    ElInput,
    ElForm,
    ElFormItem,
  },
  setup() {
    const router = useRouter();
    const show = ref(false);
    const form = reactive({
      keyword: '',
    });
    const submit = () => {
      if (router) {
        router.push({ name: 'Search', query: { ...form } });
        show.value = false;
      } else {
        window.location.href =
          process.env.VUE_APP_PUBLIC_URL + '/search?keyword=' + form.keyword;
      }
    };

    return {
      submit,
      form,
      show,
    };
  },
};
</script>
<style lang="scss" scoped>
:deep .el-drawer__header {
  @apply p-0;
}
</style>
