<template>
  <div
    class="font-bold text-sm w-full truncate max-w-[100px]"
    v-if="auth.isAuth"
  >
    HI, {{ auth.user.nickname }}
  </div>
</template>
<script>
import { inject } from 'vue';
export default {
  setup() {
    const auth = inject('auth');
    return { auth };
  },
};
</script>
<style lang=""></style>
