<template>
  <div class="relative" :class="{ hidden: $route?.meta.full }">
    <div class="relative">
      <div
        class="
          absolute
          top-0
          right-0
          h-full
          w-1/2
          bg-gray-100
          z-0
          hidden
          lg:block
        "
      ></div>
      <div class="container flex flex-wrap relative">
        <div
          class="
            w-full
            px-6
            lg:px-0 lg:w-1/3
            py-12
            flex
            lg:flex-row
            flex-col
            items-start
          "
        >
          <img class="h-32 mr-8" src="@/assets/logo-vertical.svg" alt="logo" />
          <div class="lg:mt-0 mt-8">
            <img
              class="h-10 mr-8"
              src="@/assets/nmh-logo.jpg"
              alt="史博館logo"
            />
            <p class="font-bold mt-4">國立歷史博物館徐州路臨時辦公室</p>
            <p class="text-sm mt-4">10055臺北市中正區徐州路21號</p>
            <p class="text-sm">TEL : 02-2361-0270</p>
            <p class="text-sm">FAX : 02-2393-1771</p>
          </div>
        </div>

        <div
          class="
            lg:w-2/3
            px-6
            w-full
            flex flex-col
            lg:flex-row
            py-12
            lg:px-10
            bg-gray-100
          "
        >
          <ul class="text-sm w-full lg:w-4/12">
            <li>
              <a href="/exhibs">展覽列表</a>
            </li>
            <!-- <li>
              <a href="/works">展品列表</a>
            </li> -->
            <li>
              <a href="/search">全站查詢</a>
            </li>
            <li>
              <a href="/single/opendata">政府網站資料開放宣告</a>
            </li>
            <li>
              <a href="/single/privacy">隱私權保護政策</a>
            </li>
            <li>
              <a href="/single/security">資訊安全政策</a>
            </li>
          </ul>
          <hr class="lg:hidden my-8" />
          <ul class="text-sm w-full lg:w-4/12">
            <li>
              <a href="https://www.nmh.gov.tw/" target="_blank"
                >國立歷史博物館官網</a
              >
            </li>
            <li>
              <a
                href="https://collections.culture.tw/nmh_collectionsweb/"
                target="_blank"
                >國立歷史博物館典藏查詢系統</a
              >
            </li>
            <!-- <li>
              <a href="https://vr.nmh.gov.tw/" target="_blank"
                >史博線上展覽環景網站</a
              >
            </li> -->
          </ul>
        </div>
      </div>
    </div>

    <div class="bg-primary py-4 text-white text-xs">
      <div class="container flex lg:flex-row flex-col">
        <div class="mr-6">建議使用Chrome 1440 x 1024 解析度瀏覽本網站</div>
        <div class="mr-6">總瀏覽人次 : {{ stats.total }}</div>
        <div class="">更新日期 : {{ stats.last_ts }}</div>
        <div class="lg:ml-auto">
          ©2021 Copyright National Museum of History. All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
export default {
  setup() {
    const stats = inject('stats');
    return { stats };
  },
};
</script>

<style lang="scss" scoped>
ul {
  li {
    @apply first:mt-0 mt-2;
  }
}
</style>
