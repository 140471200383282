import { useRoute, useRouter } from 'vue-router';
import { ElMessageBox } from 'element-plus';
import { ref } from 'vue';
export default () => {
  const valid = ref(false);
  const route = useRoute();
  const router = useRouter();
  const setValid = (status) => {
    if (status === 8106) {
      valid.value = '展覽不存在';
    } else if (status !== 'Published') {
      valid.value = '展覽維護中';
    } else {
      valid.value = true;
    }

    if (valid.value !== true) {
      ElMessageBox.alert(valid.value, { center: true }).then(() => {
        if (router) {
          router.push({ name: 'Home' });
        } else {
          window.location.href = '/';
        }
      });
    }
  };

  return { valid, setValid };
};
