<template>
  <div class="main-menu">
    <el-button class="p-3" @click.prevent="show = true" type="primary">
      <img src="@/assets/menu.svg" alt="" />
    </el-button>
    <Drawer v-model:show="show">
      <div class="flex flex-col text-center h-full">
        <a class="mb-4" href="#" @click.prevent="to('/')">首頁</a>
        <!-- <a class="mb-4" href="#" @click.prevent="to('/works')">藏品列表</a> -->
        <a class="mb-4" href="#" @click.prevent="to('/exhibs')">展覽列表</a>
        <a
          v-if="auth.isAuth"
          class="mb-4"
          href="#"
          @click.prevent="to('/member')"
          >策展管理</a
        >
        <a
          v-if="auth.isAuth"
          class="mb-4 mt-auto"
          href="#"
          @click.prevent="logout"
          >登出</a
        >
      </div>
    </Drawer>
  </div>
</template>

<script>
import { ElButton } from 'element-plus';
import Drawer from '@/components/Drawer';
import { logout as AuthLogout } from '@/useAuth';
import { ref, inject } from 'vue';

export default {
  components: {
    Drawer,
    ElButton,
  },
  setup() {
    const show = ref(false);

    const auth = inject('auth');
    const logout = () => {
      AuthLogout()
        .then(() => {})
        .finally(() => {
          window.location.href = '/';
        });
    };
    const to = (url) => {
      show.value = false;
      window.location.href = url;
    };
    return {
      auth,
      logout,
      to,
      show,
    };
  },
};
</script>
