import { reactive } from 'vue';

export default (status) => {
  const state = reactive({
    show: false,
    component: null,
    attrs: { width: '96%', top: '2rem' },
  });
  const toggle = (params) => {
    if (!params) {
      if (status) {
        status.set();
      }

      state.show = false;
      state.component = null;
      // state.attrs = {};
    } else {
      if (status) {
        status.set('dialoging');
      }

      state.component = params.component;
      state.attrs = { width: '96%', top: '2rem', ...params.attrs };
      state.show = true;
    }
  };

  return {
    toggle,
    state,
  };
};
