import { reactive, ref, toRefs } from 'vue';
import statsService from '@/services/Stats';

export default () => {
  const stats = reactive({
    total: 0,
    last_ts: '',
  });
  const getStats = () => {
    return statsService
      .get()
      .then((res) => {
        if (res.code === 1) {
          stats.total = res.data.total;
          stats.last_ts = res.data.last_ts;
        }
      })
      .finally(() => {});
  };
  getStats();
  return { stats };
};
