import Axios from './http';

export default class Auth {
  static async status(params) {
    return Axios('/member/status', {
      method: 'post',
      responseType: 'json',
    }).then((res) => {
      return res;
    });
  }
  static async logout(params) {
    return Axios('/member/logout', {
      method: 'post',
      responseType: 'json',
    }).then((res) => {
      return res;
    });
  }
}
