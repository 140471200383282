<template>
  <component
    class="h-full w-full detail"
    :class="type == 2 ? 'plain' : 'solid'"
    :images="JSON.parse(images)"
    :model_url="model_url"
    :is="cmpt"
  ></component>
</template>

<script>
import { computed, toRefs } from 'vue';

import PlainDetail from '@/partials/detail/_Plain.vue';
import SolidDetail from '@/partials/detail/_Solid.vue';

export default {
  props: {
    type: {
      type: Number,
    },
    images: {
      type: String,
    },
    model_url: {
      type: String,
    },
  },
  components: { PlainDetail, SolidDetail },
  setup(props) {
    const { images, type } = toRefs(props);
    console.log(images);
    const cmpt = computed(() => {
      return type.value == 2
        ? 'PlainDetail'
        : type.value == 3
        ? 'SolidDetail'
        : null;
    });

    return {
      cmpt,
    };
  },
};
</script>
<style lang="scss">
.detail {
  aspect-ratio: 12/12;
  &.plain {
    aspect-ratio: 10 /12;
  }
}
</style>
