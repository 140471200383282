<template>
  <div class="py-2">
    <div class="py-1 flex items-center" v-for="row in rows" :key="row.id">
      <template v-if="row.type === 'Mp3'">
        <Audio-Player :url="row.file" :title="row.summary || '附加檔案'" />
      </template>
      <template v-else>
        <a
          class="underline flex items-center"
          :href="$filters.uploadUrl(row.file)"
        >
          <img class="mr-[18px] w-4" :src="icons[row.type || 'File']" />
          <span>
            {{ row.summary || '附加檔案' }}
          </span>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import AudioPlayer from '@/components/Audio.vue';
import audio from '@/assets/audio.svg';
import file from '@/assets/edit.svg';
export default {
  props: {
    rows: {
      type: Object,
    },
  },
  components: {
    AudioPlayer,
  },
  setup(props) {
    const icons = {
      Mp3: audio,
      File: file,
    };

    return { icons };
  },
};
</script>
