import { GRID } from './Config';

// import EasyStar from 'easystarjs';

const getCenter = (x, y, w, h, rotation) => {
  let pos;
  const offsetH = h / 2;
  const offsetW = w / 2;

  switch (Math.round(rotation)) {
    case 0:
      pos = {
        x: x + offsetW,
        y: y + offsetH,
      };
      break;
    case 90:
      pos = {
        x: x - offsetH,
        y: y + offsetW,
      };
      break;
    case 180:
    case -180:
      pos = {
        x: x - offsetW,
        y: y - offsetH,
      };
      break;
    case -90:
    case 270:
      pos = {
        x: x + offsetH,
        y: y - offsetW,
      };
      break;
  }
  return pos;
};

const getSnapPos = (pos) => {
  return {
    x: Math.round(pos.x / GRID) * GRID,
    y: Math.round(pos.y / GRID) * GRID,
  };
};
const fixTransform = (item) => {
  item.position({
    x: Math.round(item.x()),
    y: Math.round(item.y()),
  });
  item.rotation(Math.round(item.rotation()));
  item.scale({
    x: 1,
    y: 1,
  });
};

const getTileMap = (items, w, h) => {
  const map = [];
  for (let i = 0; i < h / GRID; i++) {
    map.push([...Array(w / GRID).fill(0)]);
  }

  items.forEach((rect) => {
    // 旋轉後 xy 座標會根者左上

    let pos = {
      w: rect.width,
      h: rect.height,
      x: rect.x,
      y: rect.y,
    };

    if (rect.rotation) {
      switch (Math.round(rect.rotation)) {
        case 0:
          pos = {
            w: rect.width,
            h: rect.height,
            x: rect.x,
            y: rect.y,
          };
          break;
        case 90:
          pos = {
            w: rect.height,
            h: rect.width,
            x: rect.x - rect.height,
            y: rect.y,
          };
          break;
        case 180:
          pos = {
            w: rect.width,
            h: rect.height,
            x: rect.x - rect.width,
            y: rect.y - rect.height,
          };
          break;
        case -90:
        case 270:
          pos = {
            w: rect.height,
            h: rect.width,
            x: rect.x,
            y: rect.y - rect.width,
          };
          break;
      }
    }

    const x = Math.max(Math.round(pos.x) - GRID, 0) / GRID;
    const y = Math.max(Math.round(pos.y) - GRID, 0) / GRID;
    const w = Math.round(pos.w + GRID * 2) / GRID;
    const h = Math.round(pos.h + GRID * 2) / GRID;
    for (let i = y; i < y + h; i++) {
      if (i < map.length) {
        for (let j = x; j < x + w; j++) {
          // if (!map[i] || map[i][j]) return
          if (j < map[0].length) {
            map[i][j] = 1;
          }
        }
      }
    }
  });

  return map;
};
// const getGridIdx = (item) => {
//     return {
//         x: item.x() + item.width() / 2 - GRID / 2,
//         y: item.y() - item.height() * 2 - GRID / 2,
//     }
// }
// VUE_APP_UPLOAD_URL
const getThumb = (filename) => {
  return filename ? filename.replace('.jpg', '_sm.jpg') : '';
};

const transformProps = (x) => {
  const w = {
    2: 10,
    3: 60,
    4: 5,
    5: 5,
  };
  const type = Number(x.type);

  return {
    id: x.id?.toString(),
    work_id: x.work_id?.toString(),
    l: parseInt(x.l) || 90,
    w: parseInt(x.w) || w[Number(x.type)],
    h: parseInt(x.h) || null,
    sorter: Number(x.sorter),
    blank: x.blank || 'no',
    others: x.others || 'no',
    mp4: x.mp4 || '',
    type,
    offsetX: parseInt(x.offsetX) || 0,
    offsetY: parseInt(x.offsetY) || 0,
  };
};
const getGridIdx = (pos) => {
  return {
    x: Math.floor(pos.x / GRID),
    y: Math.floor(pos.y / GRID),
  };
};
const UUID = () => {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};
let host = window.location.host;
const uploadUrl = (filename, size = null) => {
  if (!filename) return '';

  let _filename;

  if (filename.startsWith('https://collections.culture.tw')) {
    _filename = filename.replace(
      'https://collections.culture.tw',
      process.env.VUE_APP_UPLOAD_URL
    );
  } else if (filename.startsWith('https://vrnmh.sense-info.co')) {
    _filename = filename.replace(
      'https://vrnmh.sense-info.co',
      process.env.VUE_APP_UPLOAD_URL
    );
  } else if (size) {
    const ext = filename.split('.').pop();
    _filename =
      process.env.VUE_APP_UPLOAD_URL +
      filename.replace('.' + ext, '_' + size + '.' + ext);
  } else {
    _filename = process.env.VUE_APP_UPLOAD_URL + filename;
  }

  return _filename;
};

const useStorage = () => {
  const set = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const get = (key) => {
    return JSON.parse(localStorage.getItem(key));
  };

  const remove = (key) => {
    localStorage.removeItem(key);
  };

  return { set, get, remove };
};

export {
  useStorage,
  transformProps,
  uploadUrl,
  fixTransform,
  getSnapPos,
  getGridIdx,
  getTileMap,
  UUID,
  getCenter,
  getThumb,
};
