import Axios from './http';

export default class Stats {
  static async room(params) {
    return Axios(`/stats/room`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async get(params) {
    return Axios('/stats/total', {
      method: 'post',
      responseType: 'json',
    });
  }
}
