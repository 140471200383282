<template>
  <a href="#" @click.prevent="to" class="group inline-block">
    <img
      class="transition-transform group-hover:scale-125"
      src="@/assets/banner.svg"
      alt="進入虛擬展場按鈕"
    />
  </a>
</template>

<script>
import { toRefs } from 'vue';

export default {
  props: {
    url: {
      type: String,
    },
  },
  setup(props) {
    const { url } = toRefs(props);

    const to = () => {
      window.location.href = url.value;
    };

    return { to };
  },
};
</script>
