import Axios from './http';

export default class Track {
  static async pass(params) {
    return Axios('/track/pass', {
      method: 'post',
      responseType: 'json',
      data: params,
    }).then((res) => {
      return res;
    });
  }
}
