import Axios from './http';
import { transformProps } from '@/utils/Utils';
export default class Work {
  /**
   * @description 查询邀請函列表
   * @return {HttpResponse} result
   */
  static async list(params) {
    return Axios('/work/find', {
      method: 'post',
      responseType: 'json',
      data: params,
    }).then((res) => {
      if (res.data?.subset) {
        res.data.subset = res.data.subset.map((x) => {
          return {
            ...x,
            id: x.id.toString(),
            slug: x.slug,
            type: Number(x.type),
            serial: x.pid,
            pic: x.pic || x.cover,
            cover: x.cover || x.pic,
            size: x.size,
            // ...transformProps(x),
          };
        });
      }
      return res;
    });
  }

  static async option(params) {
    return Axios('option/categories', {
      method: 'post',
      responseType: 'json',
    });
  }
  static async history(params) {
    return Axios('work/exhibs', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async materials(params) {
    return Axios('work/materials', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async find(params) {
    return Axios('work/detail', {
      method: 'post',
      responseType: 'json',
      data: params,
    }).then((res) => {
      if (res.data) {
        const x = res.data;
        res.data = {
          ...x,
          id: x.id.toString(),
          type: Number(x.type),
          serial: x.pid,
          pic: x.pic || x.cover,
          cover: x.cover || x.pic,
          year: x.year,
          creator: x.creator || x.author,
          category: x.cate,
          size: x.size,
          // ...transformProps(x),
        };
      }
      return res;
    });
  }
}
