import { reactive, ref, toRefs } from 'vue';
import authService from '@/services/Auth';
export const auth = reactive({
  checked: false,
  isAuth: false,
  isLogining: false,
  isStaff: 0,
  next: null,
  user: {},
});

const set = (_isAuth) => {
  auth.isAuth = _isAuth;
  if (!_isAuth) {
    localStorage.removeItem('isAuth');
  }
};

export const loading = ref(false);
export const status = () => {
  return authService
    .status()
    .then((res) => {
      if (res.data?.isLogined) {
        auth.user.nickname = res.data.user.nickname;
        auth.isStaff = res.data.isStaff;
        set(true);
      } else {
        set(false);
      }
      return res;
    })
    .finally(() => {
      auth.checked = true;
      loading.value = false;
    });
  // const _isAuth = localStorage.getItem('isAuth');
  //state.isAuth = _isAuth && _isAuth.toLowerCase() === 'true';
};

export const login = () => {
  loading.value = true;
  return new Promise((resolve) => {
    setTimeout(() => {
      set(true);
      auth.next();
      resolve();
      loading.value = false;
    }, 1000);
  });
};
export const logout = () => {
  loading.value = true;
  return authService
    .logout()
    .then((res) => {
      console.log(res);
      set(false);
    })
    .finally(() => {
      loading.value = false;
    });
};
