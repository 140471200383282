import trackService from '@/services/Track';

export const pass = (opt, stat) => {
  let test = false;
  if (test) return;
  trackService.pass({
    ea: opt.ea || 'open',
    ec: opt.ec || 'home', //name
    el: opt.el, //id
    ...stat,
  });
};

export const GTAG = ({ ea, ec, el }) => {
  console.log('gtag track:' + JSON.stringify({ ea, ec, el }));
  window.gtag('event', ea, {
    event_category: ec,
    event_label: el,
  });
};

let interval;
export default function track(opt) {
  if (interval) {
    clearTimeout(interval);
  }
  pass(opt);
  console.log(' track:' + JSON.stringify({ ...opt }));
  interval = setInterval(() => {
    pass({ ea: 'stay' });
  }, 30 * 60 * 1000);
}
