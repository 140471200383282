<template>
  <div
    class="
      flex
      items-center
      text-right
      lg:text-left
      bg-gray-100
      h-[56px]
      lg:pl-[158px]
    "
  >
    <div class="w-full flex-grow">
      <slot name="title">
        <h1 class="text-3xl tracking-widest">{{ title }}</h1>
      </slot>
    </div>
    <div class="lg:ml-4 pr-6 flex-none">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
  },
  setup() {
    return {};
  },
};
</script>

<style></style>
