// const GRID_PIXEL = 20;
// const GRID_SIZE = 50;
const GRID = 30;
const RATIO = 4;
const ROLE_Z = 180;
const ROOM_COLOR = '#ffffff';
const ROOM_HEIGHT = 600;
const FLOOR_HEIGHT = 10;
const CANVAS_LIMIT = 600;

const WORK_DEFAULT_L = 120;
// 20坪: 10 x 6.5米
// 60坪: 17 x 11.5米
// 80坪: 20 x 13米
const STAGE_SIZE = {
  small: {
    name: '小型(20坪)',
    width: 10 * 120,
    height: 6 * 100,
  },
  medium: {
    name: '中尺寸(60坪)',
    width: 18 * 100,
    height: 12 * 100,
  },
  large: {
    name: '大尺寸(80坪)',
    width: 21 * 100,
    height: 15 * 100,
  },
};
const ITEM_TYPES = {
  1: 'wall',
  2: 'plain',
  3: 'solid',
  4: 'intro',
  5: 'picture',
  outer: 'outer',
};
const ITEM_TYPE_COLORS = {
  1: '#',
  2: '#F01D8C',
  3: '#F01D8C',
  4: '#31C9A2',
  5: '#E6A634',
};
const FRAMES = {
  'plain-light-wood': {
    name: '淺木平框',
  },
  'plain-dark-wood': {
    name: '深棕色木平框',
  },
  'plain-walnut-wood': {
    name: '胡桃木紋平框',
  },
  'plain-black-metal': {
    name: '黑色金屬平框',
  },
};

const FONTS = [
  {
    id: 'Roboto',
    name: 'Roboto',
  },
  {
    id: 'Merriweather',
    name: 'Merriweather',
  },
  {
    id: 'Noto Sans TC',
    name: '思源黑體',
  },
  {
    id: 'Noto Serif TC',
    name: '思源明體',
  },
  {
    id: 'cwTeXKai',
    name: '楷體',
  },
  {
    id: 'cwTeXYen',
    name: '圓體',
  },
];
const FLOOR_TEXTURE = '129_light-parquet-texture-seamless';
const FLOOR_COLOR = '#333333';
const LIGHT_DIS = 640;
const TEXTURES = {
  // '51_geometric-wallpaper-texture-seamless': {
  //   name: '幾何',
  //   img: '51_geometric-wallpaper-texture-seamless.jpg',
  //   contrast: 1,
  //   type: 'wall',
  // },
  // '2_gray-wallpaper-texture-seamless': {
  //   name: '灰色',
  //   img: '2_gray-wallpaper-texture-seamless.jpg',
  //   type: 'wall',
  // },
  // '76_leather-texture-seamless': {
  //   name: '皮革',
  //   img: '76_leather-texture-seamless.jpg',
  //   type: 'wall',
  // },
  '97_leather-texture-seamless': {
    name: '土黃皮革',
    img: '97_leather-texture-seamless.jpg',
    type: 'wall',
  },
  '111_leather-texture-seamless': {
    name: '白色皮革',
    img: '111_leather-texture-seamless.jpg',
    type: 'wall',
  },
  '112_leather-texture-seamless': {
    name: '米白皮革',
    img: '112_leather-texture-seamless.jpg',
    type: 'wall',
  },
  // '0044-light-beige-travertine-texture-seamless': {
  //   name: '淺褐色石灰',
  //   img: '0044-light-beige-travertine-texture-seamless.jpg',
  //   type: 'wall',
  // },
  // '0062-light-blue-rice-paper-texture-seamless': {
  //   name: '淡藍色宣',
  //   img: '0062-light-blue-rice-paper-texture-seamless.jpg',
  //   type: 'wall',
  // },
  '68_cherry-wood-fine-medium-color-texture-seamless': {
    name: '玫瑰木',
    img: '68_cherry-wood-fine-medium-color-texture-seamless.jpg',
    type: 'floor',
  },
  '129_light-parquet-texture-seamless': {
    name: '淡色原木',
    img: '129_light-parquet-texture-seamless.jpg',
    type: 'floor',
  },

  // '108_Walnut-fine-wood-texture-seamless': {
  //   name: '核桃木',
  //   img: '108_Walnut-fine-wood-texture-seamless.jpg',
  //   type: 'floor',
  // },
  '118_Alder-fine-wood-medium-color-texture-seamless': {
    name: '細榿木',
    img: '118_Alder-fine-wood-medium-color-texture-seamless.jpg',
    type: 'floor',
  },
  '20_grey-carpeting-texture-seamless': {
    name: '淺灰色地毯',
    img: '20_grey-carpeting-texture-seamless.jpg',
    type: 'floor',
  },
  '22_grey-carpeting-texture-seamless': {
    name: '灰色地毯',
    img: '22_grey-carpeting-texture-seamless.jpg',
    type: 'floor',
  },
  // '23_grey-carpeting-texture-seamless': {
  //   name: '深灰色地毯',
  //   img: '23_grey-carpeting-texture-seamless.jpg',
  //   type: 'floor',
  // },
  // '20_grey-carpeting-texture-seamless': {
  //   name: '灰地毯',
  //   img: '20_grey-carpeting-texture-seamless.jpg',
  //   type: 'floor',
  // },
  // '83_Leccese-flooring-stone-CRACKS': {
  //   name: '萊切斯裂縫石材',
  //   img: '83_Leccese-flooring-stone-CRACKS.jpg',
  //   type: 'floor',
  // },
  // '61_scratch-eroded-metal-texture-seamless': {
  //   name: '刮蝕金屬',
  //   img: '61_scratch-eroded-metal-texture-seamless.jpg',
  //   type: 'floor',
  // },
  // '234_scratch-metal-facade-cladding-texture-seamless': {
  //   name: '刮金屬外立面覆層',
  //   img: '234_scratch-metal-facade-cladding-texture-seamless.jpg',
  //   type: 'floor',
  // },
  // '38_slab-marble-America-white-texture-seamless': {
  //   name: '美國白平板大理石',
  //   img: '38_slab-marble-America-white-texture-seamless.jpg',
  //   type: 'floor',
  // },
  // '106_wood-effect-stoneware-floor-PBR-texture-seamless': {
  //   name: '仿木效果陶土地板',
  //   img: '106_wood-effect-stoneware-floor-PBR-texture-seamless.jpg',
  //   type: 'floor',
  // },
  // '23_colored-corrugated-cardboard-texture-seamless': {
  //   name: '瓦楞',
  //   img: '23_colored-corrugated-cardboard-texture-seamless.jpg',
  //   type: 'base',
  // },
  // '44_cotton-wallpaper-texture-seamless': {
  //   name: '棉壁',
  //   img: '44_cotton-wallpaper-texture-seamless.jpg',
  //   type: 'base',
  // },
  // '84_dark-fine-wood-texture-seamless': {
  //   name: '深色細木',
  //   img: '84_dark-fine-wood-texture-seamless.jpg',
  //   type: 'base',
  // },
  '74_wood-fine-medium-color-texture-seamless': {
    name: '淺色木',
    img: '74_wood-fine-medium-color-texture-seamless.jpg',
    type: 'base',
  },
  '71_wood-fine-medium-color-texture-seamless': {
    name: '深色木',
    img: '71_wood-fine-medium-color-texture-seamless.jpg',
    type: 'base',
  },
  '86_dark-fine-wood-texture-seamless': {
    name: '深色細木',
    img: '86_dark-fine-wood-texture-seamless.jpg',
    type: 'base',
  },
};
const EXHIB_STATUS = {};
const SOCIALS = [
  {
    network: 'facebook',
    name: 'Facebook',
    icon: 'fab fah fa-lg fa-facebook-f',
    color: '#1877f2',
  },

  {
    network: 'line',
    name: 'Line',
    icon: 'fab fah fa-lg fa-line',
    color: '#00c300',
  },
  {
    network: 'linkedin',
    name: 'LinkedIn',
    icon: 'fab fah fa-lg fa-linkedin',
    color: '#007bb5',
  },

  {
    network: 'pinterest',
    name: 'Pinterest',
    icon: 'fab fah fa-lg fa-pinterest',
    color: '#bd081c',
  },

  {
    network: 'twitter',
    name: 'Twitter',
    icon: 'fab fah fa-lg fa-twitter',
    color: '#1da1f2',
  },
];
const FRAME_WIDTH = 8;
export {
  SOCIALS,
  WORK_DEFAULT_L,
  GRID,
  RATIO,
  STAGE_SIZE,
  ROLE_Z,
  ROOM_HEIGHT,
  ROOM_COLOR,
  ITEM_TYPES,
  ITEM_TYPE_COLORS,
  FLOOR_HEIGHT,
  TEXTURES,
  FLOOR_TEXTURE,
  FLOOR_COLOR,
  FONTS,
  FRAMES,
  CANVAS_LIMIT,
  FRAME_WIDTH,
  LIGHT_DIS,
  // WIDTH,
  // HEIGHT
};
