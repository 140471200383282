<script>
import useStatus from '@/components/useStatus';
import { toRefs } from 'vue';

export default {
  props: {
    status: {
      String,
    },
  },
  setup(props) {
    const { status } = toRefs(props);

    const { valid, setValid } = useStatus();
    setValid(status.value);
    return { valid };
  },
};
</script>
