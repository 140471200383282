<template>
  <p class="">觀展人數 : {{ count }} 人次</p>
</template>
<script>
import { ref, toRefs } from 'vue';
import StatsService from '@/services/Stats';
export default {
  props: {
    id: { type: String },
  },
  setup(props) {
    const { id } = toRefs(props);
    const count = ref(0);
    StatsService.room({ pid: id.value }).then((res) => {
      if (res.code === 1) {
        count.value = res.data.total || 0;
      }
    });

    return { count };
  },
};
</script>
