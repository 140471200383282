<template>
  <Materials :rows="JSON.parse(rows)" />
</template>
<script>
import Materials from '@/components/Materials';
export default {
  components: {
    Materials,
  },
  props: {
    rows: {
      type: String,
    },
  },
  setup() {
    return {};
  },
};
</script>
