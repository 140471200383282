<template>
  <div ref="canvasRefWrap" class="w-full bg-gray-100 h-full relative">
    <transition name="progress">
      <div
        class="absolute top-0 left-0 w-full h-full flex justify-center items-center"
        v-if="percentage < 100"
      >
        <El-Progress
          :show-text="false"
          type="circle"
          color="var(--color-primary)"
          :percentage="percentage"
        />
      </div>
    </transition>

    <div id="detailContainer" class="w-full h-full lg:overflow-hidden">
      <canvas ref="canvasRef"></canvas>
    </div>
    <div class="absolute bottom-0 left-0 px-4 py-4 flex w-full">
      <span class="flex items-center text-xs text-black">
        <img class="w-6 h-6 mr-2" src="@/assets/exhibitdrag.svg" alt="" />
        按著滑鼠左或右鍵，可旋轉或平移觀看細節。
      </span>
      <a
        href="#"
        class="ml-auto"
        @click.prevent="
          $modal.toggle({
            component: 'Visit',
          })
        "
      >
        <i class="">
          <img src="@/assets/howto.svg" alt="" />
        </i>
      </a>
    </div>
  </div>
</template>

<script>
// import Track from '@/utils/Track';
import {
  onUnmounted,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  inject,
  toRefs,
  nextTick,
} from 'vue';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { uploadUrl } from '@/utils/Utils';
import { mergeMesh } from '@/modules/room/utils/MeshTransform';
import { useResizeObserver } from '@vueuse/core';
import { ElProgress } from 'element-plus';
// import SelectionService from '@/services/Selection';

export default {
  props: {
    model_url: {
      type: String,
    },
  },
  components: { ElProgress },
  setup(props) {
    const { model_url } = toRefs(props);

    if (!model_url.value) return;
    const canvasRef = ref(null);
    const canvasRefWrap = ref(null);
    const activeItem = inject('activeItem');
    let scene, renderer, camera, controls;
    let animateReq;
    const row = reactive({});

    const resizeCanvas = (width, height) => {
      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      renderer.setSize(width, height);
    };

    const run = () => {
      const _animate = () => {
        animateReq = requestAnimationFrame(_animate);
        controls.update();
        renderer.render(scene, camera);
      };
      _animate();
    };

    const percentage = ref(0);

    const loader = new GLTFLoader();
    const loadModel = () => {
      loader.load(
        // resource URL
        uploadUrl(model_url.value),

        // called when the resource is loaded
        function (gltf) {
          let meshes = [];
          let mesh;

          gltf.scene.traverse(function (child) {
            if (child.isMesh) {
              meshes.push(child);
            }
          });
          mesh = mergeMesh(meshes);
          mesh.scale.set(0.1, 0.1, 0.1);
          scene.add(mesh);
        },
        function (xhr) {
          percentage.value = (xhr.loaded / xhr.total) * 100;
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
        },
        function (error) {
          console.log('An error happened');
        }
      );
    };

    const init = () => {
      const dis = activeItem
        ? Math.max(activeItem.item.meta.l, activeItem.item.meta.h)
        : 80;

      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(45, 700 / 600, 0.1, 1000);
      camera.position.set(dis * 2, dis * 1, 20);

      camera.up.set(0, 0, 1);
      scene.add(camera);

      renderer = new THREE.WebGLRenderer({ canvas: canvasRef.value });

      renderer.setClearColor(0x666666, 1.0);

      renderer.shadowMap.enabled = true;

      loadModel();

      const light = new THREE.HemisphereLight(0xffffff, 0xffffff, 1.1);
      scene.add(light);

      controls = new OrbitControls(camera, renderer.domElement);

      run();
    };
    onUnmounted((params) => {
      console.log('onUnmounted');
    });
    onBeforeUnmount(() => {
      // container.destroy();
      cancelAnimationFrame(animateReq);

      renderer.forceContextLoss();
      renderer.dispose();

      console.log('onBeforeUnmount');
    });
    onMounted(() => {
      console.log('onMounted');
      // container = document.getElementById('detailContainer');
      init();
    });
    nextTick(() => {
      useResizeObserver(canvasRefWrap, (entries) => {
        const entry = entries[0];
        if (!entry) return;

        const { width, height } = entry.contentRect;

        resizeCanvas(width, height);
      });
    });
    return {
      canvasRef,
      percentage,
      canvasRefWrap,
      row,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep #detailContainer {
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
