<template>
  <div>
    <a href="#" class="flex items-center" @click.prevent="toggle">
      <span class="w-6 mr-2">
        <img v-if="!isPlaying" src="@/assets/audio-pause.svg" alt="" />
        <img v-else src="@/assets/audio.svg" alt=""
      /></span>
      <span>
        {{ title }}
      </span>
    </a>
    <audio ref="audioRef" :src="$filters.uploadUrl(url)" :loop="loop"></audio>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    url: {
      type: String,
    },
    title: {
      type: String,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const audioRef = ref(null);

    const isPlaying = ref(false);
    const toggle = () => {
      isPlaying.value = !isPlaying.value;
      if (isPlaying.value) {
        audioRef.value.play();
      } else {
        audioRef.value.pause();
      }
    };
    return { audioRef, isPlaying, toggle };
  },
};
</script>
